import styled from "styled-components";
import tw from "twin.macro";



export const Container = styled.div`
  ${tw`relative bg-[rgba(105, 85, 65, 0.5)] -mx-8 px-8`}
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Apply the shadow all around */
`;
export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg= tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl= tw.div`max-w-screen-2xl mx-auto`;
