import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import Hero from "./components/hero/backgroundHome.js";
import Biographie from "./components/hero/backgroundBio.js";
import Footer from "./components/footers/footer.js";
import Testimonials from "./components/testimonials/testimonials.js";  // Correct the path spelling
import VideoSection from "./components/VideoSection.jsx";
import HeaderComponent from "./components/headers/light.js";
import SpectaclesBackground from "./components/hero/backgroundSpectacles.js";
import styled from "styled-components";
import Contact from "./components/contact.jsx";


const MainContentContainer = styled.div`
  margin-top: 28px; /* Adjusted the calculation for simplicity */
`;

function App() {
  return (
    <Router>
      <HeaderComponent />
      <MainContentContainer>
        <AnimationRevealPage>
          <Routes>
            <Route path="/biographie" element={<Biographie />} />
            <Route path="/spectacles" element={
              <>
                <SpectaclesBackground />
                <VideoSection />
              </>
            } />
            <Route path="/contact" element={<Contact />} />
            <Route 
              path="/" 
              element={
                <>
                  <Hero />
                  <VideoSection />
                  <Testimonials />
                </>
              } 
            />
          </Routes>
          <Footer />
        </AnimationRevealPage>
      </MainContentContainer>
    </Router>
  );
}

export default App;
