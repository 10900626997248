import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import LogoImage from "../../images/guillaume_lafond_images/gullaume-dessin-visage-logo.webp";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as EmailIcon } from "../../images/email_icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram_icon.svg";

const Container = styled.div`
  ${tw`relative bg-[rgba(175,155,135,1)] -mx-8 -mb-8 px-8`}
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
`;

const ContentCentered = tw.div`flex flex-col items-center justify-center`;

const LogoContainer = tw.div`flex flex-col items-center justify-center mt-12`;

const LogoImg = tw.img`w-10`;

const LogoText = tw.h5`text-2xl font-black text-[#3a291f] my-4`;

const SocialLinksContainer = tw.div`flex justify-center items-center mt-4`;

const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full text-gray-100 transition duration-300 mx-2`}
  svg {
    ${tw`w-8 h-8 fill-current`}
    fill: #3a291f;
  }
  &:hover {
    ${tw`transform scale-105`}
  }
`;

const CopyrightText = styled.p`
  ${tw`text-sm font-black text-[#3a291f] mt-4 mb-12`}
  @media (max-width: 500px) {
    font-size: 0.9rem; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }
`;



export function sendEmail() {
  window.location.href = "mailto: info@guillaumelafond.com";
}

//make copyright text for current year
const currentYear = new Date().getFullYear();


const Footer = () => {
  return (
    <Container>
      <ContentCentered>
        <LogoContainer>
          <LogoImg src={LogoImage} alt="Guillaume Lafond" />
          <LogoText>Guillaume Lafond</LogoText>
        </LogoContainer>
        <SocialLinksContainer>
          <SocialLink href="https://www.facebook.com/guillaumelafond.musique" className="facebook">
            <FacebookIcon />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/channel/UCmyJU0D8O-X4Rvw8tOyKGhg" className="youtube">
            <YoutubeIcon />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/guillaumelafond.musique/?hl=en" className="instagram">
            <InstagramIcon />
          </SocialLink>
          <SocialLink href="#" className="email" onClick={sendEmail}>
            <EmailIcon />
          </SocialLink>
        </SocialLinksContainer>
        <CopyrightText>
          &copy; {currentYear} Guillaume Lafond. Tous droits réservés.
        </CopyrightText>
      </ContentCentered>
    </Container>
  );
};

export default Footer;
