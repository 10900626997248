import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import guillaumeBackground from "../../images/guillaume_lafond_images/newBackground.jpg"; // Make sure this path is correct
import album1 from "../../images/guillaume_lafond_images/album1.jpg";
import album2 from "../../images/guillaume_lafond_images/album2.jpeg";
import album3 from "../../images/guillaume_lafond_images/Coup_de_poing.png";

const Container = styled.div`
  ${tw`relative bg-center bg-no-repeat min-h-screen w-screen`}
  background-image: url(${guillaumeBackground});
  background-size: cover; /* Ensure full coverage */
  background-position: left center; /* Move image to the left */
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  /* Adjusting height */
  height: 100vh; /* Sets the height to be at least the full viewport height */
  min-height: 500px; /* Sets a minimum height for the image */

  /* Media Query for screens smaller than 1200px */
  @media (max-width: 1200px) {
    background-size: cover;
    background-position: 10% center;
  }

  /* Media Query for screens smaller than 768px */
  @media (max-width: 768px) {
    background-size: cover;
    background-position: 50% center;
  }

  /* Media Query for screens smaller than 500px */
  @media (max-width: 500px) {
    background-size: cover;
    background-position: 40% center;
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-20 relative mx-auto h-full flex flex-col`;

const AlbumAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 500px) {
    margin-right: 0; /* Remove right margin on smaller screens */
    margin-bottom: 30px; /* Reduced bottom margin on smaller screens */
  }
`;

const LargeImageContainer = styled(AlbumAndButtonContainer)`
  margin-top: 14rem; /* Adjust as needed */
  @media (max-width: 600px) {
    margin-top: 2rem; /* Adjust as needed for smaller screens */
  }
`;

const Content = styled.div`
  ${tw`flex justify-between items-center h-full`}
  padding-left: 2%;
  padding-right: 2%;
  gap: 30px; /* Reduced space between album containers */

  @media (max-width: 500px) {
    ${tw`items-center`}
    flex-direction: column; /* Stack items vertically on smaller screens */
    padding-right: 0; /* Remove right padding on smaller screens */
    padding-top: 50px; /* Adjusted to move content lower on smaller screens */
  }

  @media (max-width: 1700px) {
    padding-top: 50px; /* Adjusted to move content lower on smaller screens */
  }
`;

const Image = styled.img`
  ${tw`rounded-lg`}
  width: 300px; // Default width
  height: auto;
  border: 2px solid #4e3b30; // Thin border with brown color
  border-radius: 10px; // Rounded corners

  @media (max-width: 1700px) {
    width: 250px; // Adjust width on smaller screens
  }

  @media (max-width: 500px) {
    width: 100px; // Adjust width on smaller screens
  }
`;

const LargeImage = styled.img`
  ${tw`rounded-lg`}
  width: 375px; // Larger width for the new album cover
  height: auto;
  border: 2px solid #4e3b30; // Thin border with brown color
  border-radius: 10px; // Rounded corners

  @media (max-width: 500px) {
    width: 215px;
    margin-right: 0; // Remove right margin
  }
`;

const Button = styled.a`
  ${tw`text-sm py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-700 transition duration-300 ease-in-out`}
  text-decoration: none;
  background-color: rgba(175,155,135,1); /* Brown color */
  cursor: pointer;
  display: block; /* Full width of the container */
  text-align: center; /* Center text in the button */
  margin-top: 1rem; /* Space above the button */
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 30px; /* Reduced gap between album containers */

  @media (max-width: 2000px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    flex-direction: row;
  }
`;

export default () => {
  return (
    <Container>
      <OpacityOverlay />
        <HeroContainer>
          <Content>
            <Column>
              <AlbumAndButtonContainer>
                <Image src={album1} alt="Guillaume Lafond album cover" />
                <Button href="https://mp3-disques.lnk.to/GuillaumeLafond_Adestination" target="_blank">
                  Écouter ici
                </Button>
              </AlbumAndButtonContainer>
              <AlbumAndButtonContainer>
                <Image src={album2} alt="Guillaume Lafond album cover" />
                <Button href="https://lnk.to/grandeourse?fbclid=IwAR0Os5TvQ5mcYYcGdunXZPMe06uHsOuUSD_KD8nQOLsiRJ_MNcRj-Jbfz34" target="_blank">
                  Écouter ici
                </Button>
              </AlbumAndButtonContainer>
            </Column>
            <LargeImageContainer>
              <LargeImage src={album3} alt="Guillaume Lafond new album cover" />
              <Button href="https://ffm.to/coupdepoing?fbclid=IwZXh0bgNhZW0CMTEAAR1PGN4F9gRpo5_QFQ76LMTZu3ItDYMioqXixnBq9j-eJK7TTEJbk1vlUe0_aem_B_lzhmEgLoanf34xG5Yu3Q" target="_blank">
                Nouvel extrait - écouter ici
              </Button>
            </LargeImageContainer>
          </Content>
        </HeroContainer>
    </Container>
  );
};
