import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl, Container } from "../misc/Layouts.js";
import {
  SectionHeading as Heading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import testimonialImage1 from "../../images/guillaume_lafond_images/testimonials_image_1.webp";
import testimonialImage2 from "../../images/guillaume_lafond_images/testimonials_image_2.webp";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch justify-between`; // Changed justify property
const TestimonialContainer = tw.div`mt-16 lg:w-3/4 max-w-lg`; // Adjusted width to lg:w-3/4
const Testimonial = tw.div`px-4 text-center mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;

const Quote = styled.blockquote`
  ${tw`mt-5 text-[#3a291f] font-medium leading-loose text-center lg:text-left italic`}; // Brown color, centered text, and italic

  // Adjust the width
  max-width: 500px; // Change this value to your desired width
`;

const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

export default ({
  testimonials = [
    {
      imageSrc: testimonialImage1,
      quote:
        "« Guillaume représente, à mes yeux et mes oreilles, le futur de la musique country au Québec. Son jeu de guitare, la qualité de sa voix ainsi que son ouverture d'esprit font de lui un artiste que je classe parmi les meilleurs avec qui j’ai eu la chance de travailler en 30 ans de carrière. Ce gars-là est ici pour rester. »",
      customerName: "David Laflèche",
    },
    {
      imageSrc: testimonialImage2,
      quote:
        "« Voici un artiste au potentiel international, un artiste authentique qui a tout pour lui: talent, charisme, singularité et qui s’exprime tel un livre ouvert. »",
      customerName: "Mario Pelchat",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>❞</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Image src={testimonial.imageSrc} />
                {/* Apply the custom Quote component */}
                <Quote>{testimonial.quote}</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>
    </Container>
  );
};