import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import guillaumeBackground from "../images/guillaume_lafond_images/guillaume_contact.jpeg";

const PageContainer = styled.div`
  ${tw`flex h-screen`}
`;

const ImageContainer = styled.div`
  ${tw`w-1/2 h-full`}
  background-image: url("${guillaumeBackground}");
  background-size: cover;
  background-position: center top;
  margin-left: calc(50% - 50vw);
`;

const InfoContainer = styled.div`
  ${tw`w-1/2 h-full flex flex-col justify-center p-12`}
  background-color: rgba(175,155,135,1); // Brown background
  //put the div higher
    top: 0;

`;

const ContactLabel = tw.h2`text-4xl font-bold mb-8 text-[#3a291f]`; // Dark brown color for the label

const ContactInfo = styled.p`
  ${tw`mb-4 text-lg leading-relaxed text-[#3a291f]`}
  word-break: normal; // Prevents text from breaking at any point

  // Styles for screens 500px wide and under
  @media (max-width: 500px) {
    font-size: 0.875rem; // Smaller font size for small screens
    word-break: normal; // Allows text to break at normal word points
  }

  a {
    color: #3a291f; // Set color to dark brown
    text-decoration: underline; // Ensure underline is shown
    word-break: normal; // Prevents text from breaking at any point
    &:hover {
      color: #f0e7db; // Lighter color on hover
      text-decoration-color: #3a291f; // Brown underline on hover
    }
  }
`;

const InfoSubContainer = styled.div`
  ${tw`absolute`}
  left: 60%; // Position at the middle on the X-axis
  top: 50%; // Position at the middle on the Y-axis
  transform: translate(-50%, -50%); // Adjust to center the subcontainer
  padding: 0 24px;
  width: auto; // Adjust the width as necessary
  max-width: 90%; // Prevent it from getting too wide on larger screens
  @media (max-width: 500px) {
    left: 65%; // Position at the middle on the X-axis
    text-align: center; // Center the text
    padding: 0 10%; // Adjust padding to give more space for text
    transform: translate(-50%, -50%); // Adjust to center the subcontainer
  }
`;



export default () => {
  return (
    <PageContainer>
      <ImageContainer />
      <InfoContainer>
        <InfoSubContainer>
            <ContactLabel>Contact</ContactLabel> {/* Removed "Informations" */}
            <ContactInfo>
            <strong>Agence Ranch - Relations de presse</strong><br/>
            Joelle Proulx:<br/>
            <a href="tel:+15148335489">514-833-5489</a><br/> {/* Added tel link */}
            <a href="mailto:joelle@agenceranch.com">joelle@agenceranch.com</a>
            </ContactInfo>
            <ContactInfo>
            <strong>Disques Farwest - Agent de spectacles</strong><br/>
            Philippe Gaudreault:<br/>
            <a href="mailto:info@disquesfarwest.com">info@disquesfarwest.com</a>
            </ContactInfo>
        </InfoSubContainer>
      </InfoContainer>
    </PageContainer>
  );
};
