// FileName: HeaderComponent.jsx

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../../images/guillaume_lafond_images/gullaume-dessin-visage-logo.webp";
import namelogo from "../../images/guillaume_lafond_images/GuillaumeLafond_logo_cl_horizontal.png";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: flex-end; // Align items to the right
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 1000;
  padding-top: 5px; /* Uniform padding */
  padding-bottom: 4px; /* Uniform padding */
  padding-left: 10px; /* pl-8 */
  transition: top 0.3s; /* Smooth transition for sliding the header */ 
`;

const NavLink = styled.a`
  font-size: 1.125rem; /* lg */
  margin-left: 2rem; /* mx-8 */
  margin-top: 0.5rem; /* my-2 */
  margin-bottom: 0.5rem; /* my-2 */
  font-weight: 600; /* font-semibold */
  color: rgba(175, 155, 135, 1); /* text-gray-300 */
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #bdbdbd; /* hover:text-gray-300 */
  }

  &.active {
    text-decoration: overline; /* underline */
    color: [rgba(175,155,135,1)]
  }
  text-decoration: none;
  display: block; /* Ensure this is block for the mobile menu */
`;

const NavLinksContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

const DesktopNavLinks = styled.nav`
  display: none;
  @media (min-width: 1000px) {
    display: flex;
  }
  padding-right: 40px; /* pr-8 */
`;

const MobileNavLinks = styled.nav`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  box-sizing: border-box;
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    display: ${(props) => (props.isopen ? 'flex' : 'none')}; // Show if isopen is true
    font-size: 1.5rem; // Set the font size
    flex-direction: column;
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    transition: all 0.3s ease-in;
    ${NavLink} {
      margin: 1rem;
    }
  }
`;

const MobileMenuIcon = styled.button`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    font-size: 2rem; // Default size
    background: none;
    border: none;
    color: rgba(175, 155, 135, 1);
    cursor: pointer;
    padding-right: 20px;
    transition: font-size 0.3s; // Smooth transition for resizing

    // Very small screens
    @media (max-width: 320px) {
      font-size: 1.5rem; // Slightly smaller to fit
    }
  }
`;


const Logo = styled.img`
  max-height: 40px; // Default size
  width: auto;
  height: auto;
  padding-right: 190px;
  transition: max-height 0.3s; // Smooth transition for resizing

  @media (max-width: 1200px) {
    max-height: 35px; // Slightly smaller
    padding-right: 150px;
  }

  // Medium screens
  @media (max-width: 768px) {
    max-height: 35px; // Slightly smaller
    padding-right: 0px;
  }

  // Smaller screens
  @media (max-width: 500px) {
    max-height: 30px; // Even smaller
    padding-right: 0px;
  }
`;

const NameLogo = styled.img`
  max-height: 50px; // Default size
  width: auto;
  height: auto;
  transform: scale(2.5);
  transform-origin: center;
  transition: max-height 0.3s, transform 0.3s; // Smooth transition

  @media (max-width: 1200px) {
    transform: scale(2);
  }

  // Medium screens
  @media (max-width: 768px) {
    transform: scale(2);
    padding-left: 100px;
  }

  // Smaller screens
  @media (max-width: 500px) {
    transform: scale(1.4);
    padding-left: 30px;

  @media (max-width: 420px) {
    transform: scale(1.10);
    padding-left: 5px;

  @media (max-width: 400px) {
    transform: scale(1.25);
    padding-left: 20px;
  }

  @media (max-width: 390px) {
    transform: scale(1.2);
    padding-left: 15px;
  }
`;



const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto; // Ensures logos are pushed to left and don't overlap the menu

  // Smaller screens
  @media (max-width: 500px) {
    gap: 10px; // Reduce the space between logos
  }
`;

const HeaderComponent = () => {
  const [hideHeader, setHideHeader] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isActive = (path) => {
    return window.location.pathname === path;
  };
  const handleScroll = () => {
    setHideHeader(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 500 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileMenuOpen]);

  const navLinks = (
      <>
        <NavLink href="/" className={isActive('/') ? 'active' : ''}>Accueil</NavLink>
        <NavLink href="/spectacles" className={isActive('/spectacles') ? 'active' : ''}>Spectacles</NavLink>
        <NavLink href="/biographie" className={isActive('/biographie') ? 'active' : ''}>Biographie</NavLink>
        <NavLink href="/contact" className={isActive('/contact') ? 'active' : ''}>Contact</NavLink>
      </>
    );

  return (
    <HeaderContainer style={{ top: hideHeader ? '-100%' : '0' }}>
        <LogosContainer>
        <a href="/">
          <Logo src={logo} alt="logo" />
        </a>
          <NameLogo src={namelogo} alt="name logo" />
        </LogosContainer>
      <NavLinksContainer>
        <DesktopNavLinks>{navLinks}</DesktopNavLinks>
        <MobileMenuIcon onClick={toggleMobileMenu}>☰</MobileMenuIcon>
        <MobileNavLinks isopen={isMobileMenuOpen}>
          {navLinks}
        </MobileNavLinks>
      </NavLinksContainer>
    </HeaderContainer>
  );
};
export default HeaderComponent;
