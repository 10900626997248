import tw from "twin.macro";

export const SectionHeading = tw.h2`
  text-4xl sm:text-5xl lg:text-6xl // Larger text sizes for different breakpoints
  font-black tracking-wide text-center
  text-[#4e3b30]  // Change to white for better contrast on darker backgrounds
  mb-8 // Added bottom margin for spacing
`;

export const Subheading = tw.h5`
  font-bold
  text-secondary-300 // Softer color for a refined look
  uppercase // Added uppercase for a more distinctive style
  tracking-widest // Increase letter spacing
  mb-4 // Added bottom margin for spacing
`;