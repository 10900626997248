import React from "react";
import styled from "styled-components";
import tw from "twin.macro";


const VideoSectionContainer = styled.div`
  ${tw`flex items-center justify-center`}
  background-color: rgba(175, 155, 135, 1);
  padding-top: 1px; // Tiny padding on top
  padding-bottom: 1px; // Tiny padding on bottom
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  @media (min-width: 1024px) {
    max-height: 1200vh; // Adjust the height as needed
  }
`;

const VideoContainer = styled.div`
  width: 100vw; // Full width of the viewport
  position: relative;
  overflow: hidden;
  padding-top: ${(9 / 16) * 100}vw; // Maintain aspect ratio for 16:9
  height: 0;

  @media (min-width: 1024px) {
    padding-top: 0;
    height: 90vh; // Set the maximum height on larger screens
  }
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;


// VideoSection component
const VideoSection = () => {
  const currentPath = window.location.pathname;
  const videoDetails = currentPath === "/spectacles" ? 
                       { url: "https://www.youtube.com/embed/7fyEEBE9aUA?autoplay=1&mute=1"} :
                       { url: "https://www.youtube.com/embed/aS6CFcv205E?autoplay=1&mute=1"};

  return (
  <VideoSectionContainer>
    <VideoContainer>
      <VideoIframe
        src={videoDetails.url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></VideoIframe>
    </VideoContainer>
  </VideoSectionContainer>
);
};

export default VideoSection;
