import tw from "twin.macro";
import styled from "styled-components";
import guillaumeBackground from "../../images/guillaume_lafond_images/Spectacle.jpg";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Container = styled.div`
  ${tw`relative bg-center bg-no-repeat bg-cover h-screen `}
  background-image: url("${guillaumeBackground}");
  padding-top: 100px; /* Adjust if necessary to avoid overlap with the header */

  /* Default background properties */
  background-size: cover;
  background-position: center center;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  /* Adjusting height */
  height: 100vh; /* Sets the height to be at least the full viewport height */
  min-height: 500px; /* Sets a minimum height for the image */

  /* Media Query for screens smaller than 500px */
  @media (max-width: 2000px) {
    background-size: cover; /* Continue using cover for a zoom effect */
    background-position: 33%; /* Adjusts to focus on 1/3 of the image */
  }

  /* Further media queries can be added here for other screen sizes or orientations */
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-20 relative mx-auto h-full flex flex-col`;

const EventsList = styled.ul`
  list-style: none; // Removes default list styling
  padding: 0;
  margin: 0;
`;

const EventItem = styled.li`
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: bold;
    color: #333; /* Adjust the text color to your preference */
  }

  p {
    margin: 0;
    color: #666; /* Adjust the text color to your preference */
  }
`;

const ShowsListing = styled.div`
  ${tw`max-w-screen-lg mx-auto p-8 bg-white rounded-lg shadow-lg`}
  background-color: rgba(255, 255, 255, 0.4); // Semi-transparent white for a light theme
  margin-top: 2rem; // Spacing from the top
  text-align: center; // Center-align the text
`;

const EventsListContainer = styled.div`
  max-height: 500px; /* Adjust the height as needed */
  overflow-y: auto;

  @media (max-width: 640px) {
    max-height: 400px; /* Adjust the height as needed */
  }
`;

const H2 = styled.h2`
  color: #3a291f;
`;

const CalendarEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const apiKey = 'AIzaSyCYUcCAnAic3xDt-Ybj1fe8w6ec7AGu5sQ'; // Replace with your Google Calendar API key
    const calendarId = 'info@guillaumelafond.com'; // Replace with your Google Calendar ID
    const url = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(
      calendarId
    )}/events?key=${apiKey}`;

    const fetchEvents = async () => {
      try {
        const response = await axios.get(url);
        setEvents(response.data.items);
      } catch (error) {
        console.error("Error fetching events: ", error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <ShowsListing>
          <H2>Spectacles à venir:</H2>
          <EventsListContainer>
          <EventsList>
            {events
              .filter(event => {
                const eventDate = new Date(event.start.dateTime || event.start.date);
                return eventDate >= new Date();
              })
              .sort((eventA, eventB) => {
                const dateA = new Date(eventA.start.dateTime || eventA.start.date);
                const dateB = new Date(eventB.start.dateTime || eventB.start.date);
                return dateA - dateB;
              })
              .map(event => {
                const eventDate = new Date(event.start.dateTime || event.start.date);
                const options = {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZoneName: 'short',
                };
                let formattedDate = eventDate.toLocaleDateString('fr-FR', options).trim();

                formattedDate = formattedDate.replace(/UTC.*/, '').trim();

                const formattedSummary = event.summary.replace(/(\d{1,2}:\d{2})[^]*$/, '');

                const description = document.createElement('div');
                description.innerHTML = event.description;
                const ticketLink = description.querySelector('a');

                return (
                  <EventItem key={event.id}>
                    <strong style={{ color: '#3a291f' }}>{formattedDate}</strong>
                    <br />
                    <br />
                    <span style={{ color: '#3a291f' }}>{formattedSummary}</span>
                    <br />
                    <div>
                      <div style={{ paddingBottom: '1px', color: '#3a291f' }}>
                        <strong style={{ color: '#3a291f' }}>Lieu :</strong> {event.location}
                      </div>
                      <br />
                      <button
                        onClick={() => {
                          if (ticketLink) {
                            window.open(ticketLink.href, '_blank');
                          } else {
                            window.open(event.ticketLink, '_blank');
                          }
                        }}
                        style={{
                          color: '#3a291f',
                          backgroundColor: 'rgba(175, 155, 135, 1)',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          padding: '3px 5px',
                        }}
                      >
                        Billet
                      </button>
                    </div>
                  </EventItem>
                );
              })}
          </EventsList>
          </EventsListContainer>
        </ShowsListing>
        {/* ... Other content ... */}
      </HeroContainer>
    </Container>
  );
};

export default CalendarEvents;