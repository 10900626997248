// AnimationRevealPage.js

import React from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import useInView from "../helpers/useInView";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-x-hidden overflow-y-auto`;

const HeroContainer = tw(motion.div)`w-full h-screen flex items-center justify-center flex-col pt-10`;

function AnimationRevealPage({ disabled, excludedComponents = [], children, noAnimation }) {
  if (disabled || noAnimation) {
    return <>{children}</>;
  }

  const shouldAnimate = (child) => !excludedComponents.includes(child.type);

  if (!Array.isArray(children)) children = [children];

  const directions = ["left", "right"];
  const childrenWithAnimation = children.map((child, i) => {
    return shouldAnimate(child) ? (
      <AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
        {child}
      </AnimatedSlideInComponent>
    ) : (
      child
    );
  });

  return <StyledDiv className="App">{childrenWithAnimation}</StyledDiv>;
}

function AnimatedSlideInComponent({ direction = "left", offset = 30, children }) {
  const [ref, inView] = useInView({ margin: `-${offset}px 0px 0px 0px` });

  const x = { target: "0%" };

  if (direction === "left") x.initial = "-150%";
  else x.initial = "150%";

  return (
    <div ref={ref}>
      <motion.section
        initial={{ x: x.initial }}
        animate={{
          x: inView && x.target,
          transitionEnd: {
            x: inView && 0,
          },
        }}
        transition={{ type: "spring", damping: 19 }}
      >
        {children}
      </motion.section>
    </div>
  );
}

export default AnimationRevealPage;
